import type {Item} from '~/types/product';
import type {WebshopProduct} from '~/graphql/graphql';
import productsQuery from '~/graphql/queries/webshop/products.gql';

interface CartProduct {
  slug: string,
  amount: number,
}

interface State {
  products: CartProduct[],
  loadedProducts: WebshopProduct[],
  loaded: boolean,
}

const defaultState: State = {
  products: [],
  loadedProducts: [],
  loaded: false,
};

function loadState(): Partial<State> {
  if (!process.client || typeof window?.localStorage === 'undefined') {
    return {};
  }

  const stored = localStorage.getItem('cart');

  if (!stored?.length) {
    return {};
  }

  return {
    products: JSON.parse(stored) ?? [],
    loaded: true,
  };
}

function saveState(state: State) {
  localStorage.setItem('cart', JSON.stringify(state.products));

  loadShoppingCartProducts(state);
}

function loadShoppingCartProducts(state: State) {
  if (!Array.isArray(state.products)) {
    state.products = [];
    saveState(state);
  }

  const {onResult} = useQuery<{ webshopProducts: WebshopProduct[] }>(productsQuery, {
    slugs: state.products.map(p => p.slug),
  });

  onResult(({data}) => {
    if (!data) {
      return;
    }

    state.loadedProducts = data.webshopProducts;
  });
}

export const useShoppingCart = () => {
  const state = useState<State>('cart', () => ({...defaultState}));
  if (!state.value.loaded) {
    state.value = {
      ...state.value,
      ...loadState(),
    };
  }

  loadShoppingCartProducts(state.value);

  const shoppingCartSize = computed(() => state.value.products.reduce((acc, prod) => acc + prod.amount, 0));

  const add = (slug: string, amount = 1) => {
    const product = state.value.products.find(p => p.slug === slug) || {slug, amount};
    const index = state.value.products.findIndex(p => p.slug === slug);

    if (index !== -1) {
      product.amount = amount;
      state.value.products[index] = product;
    } else {
      state.value.products.push(product);
    }

    saveState(state.value);
  };

  const remove = (slug: string, amount = 1) => {
    const index = state.value.products.findIndex(p => p.slug === slug);

    if (index === -1) {
      return;
    }

    const newAmount = state.value.products[index].amount - amount;

    if (newAmount === 0) {
      state.value.products.splice(index, 1);
    } else {
      state.value.products[index].amount = newAmount;
    }

    saveState(state.value);
  };

  const set = (slug: string, amount = 1) => {
    const currentProductCount = countProducts(slug);
    const diff = amount - currentProductCount;
    if (diff < 0) {
      remove(slug, -diff);
    } else if (diff > 0) {
      add(slug, currentProductCount + diff);
    }
  };

  const empty = () => {
    state.value.products = [];
    saveState(state.value);
  };

  const countProducts = (slug: string): number => {
    return state.value.products.find(p => p.slug === slug)?.amount ?? 0;
  };

  const getProducts = () => {
    return state.value.products;
  };

  const cartParsed = computed(() => {
    return getProducts().map((cartProduct) => {
      const product = state.value.loadedProducts.find(p => p.slug === cartProduct.slug);

      if (!product) {
        return null;
      }

      return {
        product: {
          category: product.category?.id ?? 0,
          slug: product.slug,
          title: product.title,
          image: product.active_image?.url ?? '/fallback/product.jpg',
          price_with_vat: product.price_with_vat,
          price_without_vat: product.price_without_vat,
          membership_discount: product.membership_discount,
        },
        amount: cartProduct.amount,
      } as Item;
    }).filter(v => !!v) as Item[];
  });

  const summary = computed(() => {
    let exVat = 0;
    let inVat = 0;
    let discount = 0;
    cartParsed.value.forEach((item) => {
      exVat += item.product.price_without_vat * item.amount;
      inVat += item.product.price_with_vat * item.amount;
      discount += item.product.price_with_vat * item.amount * item.product.membership_discount / 100;
    });
    return {
      price_with_vat: inVat,
      price_without_vat: exVat,
      membership_discount: discount,
      total: inVat - discount,
    };
  });

  return {
    shoppingCartSize,
    cartParsed,
    summary,
    getProducts,
    add,
    remove,
    set,
    empty,
  };
};
